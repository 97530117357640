* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth; 
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #111;
  color: #fff;
  line-height: 1.6;
  overflow-x: hidden;
}

.header {
  background-color: rgba(0, 0, 0, 0.9);
  color: #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-links,
.tech-stack {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.nav-links a,
.tech-stack a {
  color: #00cc99;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-links a:hover,
.tech-stack a:hover {
  color: #007acc;
  text-decoration: underline;
  transform: scale(1.05);
}

.company-link {
  color: #00cc99;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.company-link:hover {
  color: #007acc;
  text-decoration: underline;
}

.tech-stack span {
  font-weight: bold;
  color: #ccc;
}

.visitor-counter {
  background-color: rgba(0, 0, 0, 0.85);
  color: #00cc99;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
  cursor: default;
}

.visitor-error {
  color: #ff4d4d;
  font-weight: bold;
}

section {
  padding: 40px 20px;
  background-color: #fff; 
  color: #333;
  text-align: center;
  border-bottom: 1px solid #dcdcdc; 
}

h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
}

h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background: #a8e6cf; 
  margin: 10px auto;
}

.education-section, .workhistory-section, .certificates-section {
  padding: 40px 20px; 
  background-color: #fff; 
  text-align: center;
}

.education-card, .certificate-card, .workhistory-card {
  background-color: #fff;
  border-left: 4px solid #a8e6cf; 
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s;
  text-align: left;
  cursor: pointer; 
  position: relative;  
}

.education-card:hover, .certificate-card:hover, .workhistory-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: #e6f7f1; 
  border-left: 4px solid #00cc99; 
}

.certificate-link {
  color: #00cc99;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.certificate-link:hover {
  color: #007acc;
  text-decoration: underline;
}

.certificate-card:hover::after {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1.2rem;
  color: #00cc99;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.certificate-card::after {
  content: '';
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0; 
  transition: opacity 0.3s ease;
}

.hyperlink:hover {
  text-decoration: underline;
  color: #007acc;
}

.education-card h3, .certificate-card h3, .company-name {
  color: #00cc99;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.education-card p, .certificate-card p, .workhistory-card p, .skill-card p {
  color: #333;
  font-size: 0.85rem;
  line-height: 1.5;
}

.role {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.date {
  display: block;
  font-weight: bold;
  color: #333;
  font-size: 0.85rem;
  margin-top: 5px;
}

.responsibilities, .skills-list {
  padding-left: 20px;
  margin-top: 10px;
  list-style: disc;
}

.responsibilities li, .skill-item {
  color: #333;
  font-size: 0.85rem;
  line-height: 1.5;
}

footer {
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  color: #ccc;
  padding: 20px;
}